<template lang="pug">
  FormModal(title="Edit Image", method="PUT", :endpoint="`blocks/${block_id}`", :data="data", v-bind="$attrs", @success="onSuccess")
    component(:is="tabsComponent")
      component(:is="tabComponent", title="Image")
        InputGroup(label="Image", name="featured_image", description="JPG, PNG, GIF or WEBP. Up to 10 MB.")
          ImageInput(v-model="data.featured_image", prepend-icon="block-image")
        InputGroup(label="URL", name="content_url")
          TextInput(v-model="data.content_url", prepend-icon="link")
        ImageDisplayInput(v-model="data.image_display")
        InputGroup(v-if="!collection_id", name="collection_ids", label="Collection(s) - optional", description="Click the chevron or your down arrow key to see list")
          MultiSelect(v-model="data.collection_ids", placeholder="Add this to a collection...", endpoint="options/all/collections", option-label="label")

        div.mt-12(v-if="collections_count > 1")
          p Changes will affect <strong>{{ collections_count }} collections</strong> using this image:
          ul.list-disc.list-inside.ml-3.mt-4.space-y-1
            li(v-for="(name, id) in collections")
              span(v-if="collection_id == id") {{ name }} #[span.text-supplement (this collection)]
              router-link(v-else, :to="{name: 'collection', params: { id }}") {{ name }}

      Tab(v-if="collection_id", title="Inspires")
        p The following are only applied to the image when it's used within Inspires collections.
        InputGroup.mt-8(label="Customisable - Optional", name="inspires_customisable")
          div.mt-2.inline-flex.items-center
            ToggleSwitch.rounded-full(v-model="data.inspires_customisable")
            span.ml-4 Allow advisors to customise this block's content
        //- InputGroup(label="Placeholder - Optional", name="inspires_placeholder")
          div.mt-2.inline-flex.items-center
            ToggleSwitch.rounded-full(v-model="data.inspires_placeholder")
            span.ml-4 Only display this block if the advisor customises it
        InputGroup(label="Downloadable - Optional", name="inspires_downloadable")
          div.mt-2.inline-flex.items-center
            ToggleSwitch.rounded-full(v-model="data.inspires_downloadable")
            span.ml-4 Display a button to download the image
</template>

<script>
import { mapGetters } from "vuex"
import { Tabs, Tab } from "@syntax51/app-kit"
import ImageDisplayInput from "../../../components/Form/ImageDisplayInput.vue"

export default {
  components: {
    ImageDisplayInput,
    Tabs,
    Tab
  },
  props: {
    block_id: {
      type: Number,
      required: true
    },
    collection_id: {
      type: Number
    }
  },
  data: () => ({
    data: {
      collection_id: null,
      collection_ids: [],
      featured_image: "",
      image_display: "cover",
      content_url: "",
      inspires_customisable: null,
      inspires_placeholder: null,
      inspires_downloadable: null
    },
    collections: {},
    tabsComponent: null,
    tabComponent: null
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    collections_count() {
      return Object.entries(this.collections).length
    }
  },
  async created() {
    this.data.collection_id = this.collection_id
    this.tabsComponent = this.user.partner.inspires_customisations_enabled && this.collection_id ? Tabs : "div"
    this.tabComponent = this.user.partner.inspires_customisations_enabled && this.collection_id ? Tab : "div"

    const query = this.collection_id ? `?collection_id=${this.collection_id}` : ""
    let response = await this.$api.get(`blocks/${this.block_id}${query}`)

    this.data = Object.assign(this.data, {
      featured_image: response.data.data.image,
      image_display: response.data.data.image_display,
      content_url: response.data.data.url,
      inspires_customisable: response.data.data.inspires_customisable,
      inspires_placeholder: response.data.data.inspires_placeholder,
      inspires_downloadable: response.data.data.inspires_downloadable,
      collection_ids: response.data.data.collection_ids
    })

    this.collections = response.data.data.collections
  },
  methods: {
    onSuccess(result) {
      this.$notification.success(`Call to action successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
